<template>
  <div class="primary-data-supplier-submit padding-top-layout">
    <div class="wrap">
      <div class="wrap__block">
        <div class="wrap__block__header wrap__header">
          <div class="wrap__block__header__content">
            <h1 class="wrap-title padding-left-layout">
              {{ $t('supplier_products.hyperlink_linked_emissions_data') }}
            </h1>
          </div>
        </div>
      </div>
    </div>
    <div
      class="category-table main-table custom-table approval-table"
      :class="[isFullScreen && 'full-screen', isExpand ? 'expanded' : 'no-expanded']"
    >
      <data-table
        :data-source="primaryDataMergedGrid"
        :grid-columns="approvalColumn"
        :init-grid="initGrid"
        :rowFocus="1"
        :allowAddNew="false"
        :filterColumns="filterColumns"
        :showFocus="false"
        :isEmptySource="isEmptySource"
        :labelEmpty="descriptionLabelEmpty"
        :isResizeCustome="false"
        :isHasData="isHasData"
        @changeFullScreen="onChangeFullScreen"
        @flexGridInitialDone="onFlexGridInitialDone"
        @onHandleActionTable="onHandleActionTable"
      />
    </div>
    <notification-popup
      :isAdmin="true"
      :dialog="dialogNotification"
      :message="messageNotitfication"
      @submit="closeNotificationPopUp"
    />
    <comment-log-popup :dialog="commentPopup" :contents="commentHistoryList" @close="commentPopup = false" />
  </div>
</template>
<script>
import DataTable from '@/components/category/data-table';
import DialogPopup from '@/components/dialogs/question-popup';
import { getListCommentHistory } from '@/api/registerData';
import { workflowDataSupplierApi, updateFormPrimaryDataLinked, updateNotification } from '@/api/product/emissionsProduct.js'
import { ROUTES } from '@/router/constants';
import { STATUS_FIELD } from '@/constants/status';
import { KEYS_CODE } from '@/constants/keyboard';
import { BLANK_ID, CANCEL_TYPE, ACTION_HEADER_TABLE } from '@/constants/registerData';
import { getStatusNameById, getColorTextStatusById } from '@/utils/status';
import { getCategoryName, getDataLayer, getScopeName } from '@/utils/registerData';
import { formatDateTimeDataTable } from '@/utils/datetimeFormat';
import { mapActions, mapState } from 'vuex';
import * as wjcCore from '@mescius/wijmo';
import { CollectionView } from '@mescius/wijmo';
import { CellMaker } from '@mescius/wijmo.grid.cellmaker';
import * as wjGrid from '@mescius/wijmo.grid';
import throttle from 'lodash/throttle';
import { updateDataBeforeRedirect } from '@/utils/updateDataBeforeRedirect';
import { cancelSubmittedData } from '@/api/newRegisterData';
import { getWidthOfDetailButton } from '@/utils/multiLanguage';
import { getWidthByTextContent, setMinMaxSizeColumns } from '@/utils/calcTextWidth';
import { getScopeCategoryMethodName, showItemName } from '@/utils/primaryDataLinked';
import { addThoundSandComma } from '@/utils/convertNumber';
import { formatBigNumber } from '@/concerns/newRegisterData/wijmo.helper';
import { ORGANIZATIONAL_DIVISION } from '@/constants/registerData';
import notificationPopup from '@/components/dialogs/notification-popup.vue';
import CommentLogPopup from "@/components/dialogs/log-confirm-popup";
import { getWidthByTextHeader } from '@/utils/multiLanguage';
import { APPROVAL_STATUS } from '@/constants/registerData';
import { formatNumberRealNum } from '@/utils/convertNumber';

export default {
  name: 'ApprovalPage',
  components: {
    DataTable,
    DialogPopup,
    notificationPopup,
    CommentLogPopup
  },
  data() {
    return {
      primaryDataMergedGrid: null,
      flexGrid: null,
      dataSupplierSubmit: [],
      approvalColumn: [],
      dialogComment: false,
      breadCrumb: [
        {
          text: this.$t('supplier_products.hyperlink_primary_data'),
          disabled: false,
          href: ROUTES.PRODUCTS_EMISSION + ROUTES.PRIMARY_DATA_MERGE,
        },
        {
          text: this.$t('supplier_products.hyperlink_linked_emissions_data'),
          disabled: true,
          href: ROUTES.PRODUCTS_EMISSION + ROUTES.PRIMARY_DATA_MERGE,
        },
      ],
      filterColumns: [],
      commentHistoryList: [],
      isEmptySource: true,
      isEditDataTable: false,
      commentPopup: false,
      layers: [],
      itemsChangeStatus: {},
      dialogNotification: null,
      messageNotitfication: this.$t('popup.popup_keep'),
    };
  },
  async mounted() {
    this.actionUpdateIsLoadingTable(true);
    await this.handleGetDataSupplierSubmit();
    this.actionUpdateIsLoadingTable(false);
    this.updateBreadCrumb(this.breadCrumb);
  },
  methods: {
    ...mapActions('commonApp', ['updateBreadCrumb', 'actionUpdateIsLoadingTable']),
    closeNotificationPopUp() {
      this.dialogNotification = false;
      this.messageNotitfication = '';
    },
    async onCommentClicking(rowData) {
      if (!rowData.item?.id) return;
      await this.handleGetCommentHistory(rowData.item?.id);
      this.commentPopup = true;
      this.dialogCancelBtn = true;
    },
    async handleGetCommentHistory(workflowDataId) {
      const payload = {
        id: workflowDataId
      };
      const commentList = await getListCommentHistory(payload);
      this.commentHistoryList = commentList.data.map(commentHistory => {
        return {
          ...commentHistory,
          date: formatDateTimeDataTable(commentHistory.updated_at),
          name: commentHistory.user.name,
          content: commentHistory.content
        };
      });
    },
    async onHandleActionTable() {
      try {
        this.primaryDataMergedGrid?.itemsEdited?.forEach(item => {
          if(!this.itemsChangeStatus[item.id]) {
            this.itemsChangeStatus[item.id] = item
          }
        })

        this.dialogNotification = true;
        this.messageNotitfication = this.$t('popup.popup_keep');
        this.itemsChangeStatus = {};
        this.isEditDataTable = false;
      } catch (error) {
        console.warn(error);
      }
    },
    initGrid(grid) {
      this.flexGrid = grid;
      document?.addEventListener('keydown', (event) => {
        if (
          (event.metaKey || event.ctrlKey) &&
          [
            KEYS_CODE.DOWN_ARROW,
            KEYS_CODE.UP_ARROW,
            KEYS_CODE.LEFT_ARROW,
            KEYS_CODE.RIGHT_ARROW,
            KEYS_CODE.ENTER,
          ].includes(event.keyCode)
        ) {
          event.preventDefault();
        }
      });

      grid.hostElement.addEventListener(
        'keydown',
        (event) => {
          if (event.metaKey || event.ctrlKey) {
            if (event.keyCode === KEYS_CODE.DOWN_ARROW) {
              const currentSelection = grid.selection;
              const cellRange = new wjGrid.CellRange(grid.rows.length - 1, currentSelection.col);
              grid.selection = cellRange;

              // re-select after add more
              setTimeout(() => {
                grid.selection = cellRange;
              }, 200);
            } else if (event.keyCode === KEYS_CODE.UP_ARROW) {
              const currentSelection = grid.selection;
              grid.selection = new wjGrid.CellRange(0, currentSelection.col);
            } else if (event.keyCode === KEYS_CODE.RIGHT_ARROW) {
              const currentSelection = grid.selection;
              grid.selection = new wjGrid.CellRange(currentSelection.row, grid.columns.length - 1);
            } else if (event.keyCode === KEYS_CODE.LEFT_ARROW) {
              const currentSelection = grid.selection;
              grid.selection = new wjGrid.CellRange(currentSelection.row, 1);
            }
          }
        },
        false,
      );
      grid.beginningEdit.addHandler((sender, event) => {
        let column = sender.columns[event.col];

        const listColumnNotDisable = ['unique_id'];
        if (!listColumnNotDisable.includes(column.binding)) {
          event.cancel = true;
        }
      });
      grid.hostElement.addEventListener('mouseover', (e) => {
        if (e.target.parentElement.classList.contains('comment-icon')) {
          e.target.src = require(`@/assets/icons/comment_active.svg`);
        }
      });
      grid.hostElement.addEventListener('mouseout', (e) => {
        if (e.target.parentElement.classList.contains('comment-icon')) {
          e.target.src = require(`@/assets/icons/comment.svg`);
        }
      });

      grid.pastingCell.addHandler((s, e) => {
          e.cancel = true;
      })

      grid.onSelectionChanged(null);
    },
    async handleGetDataSupplierSubmit() {
      const payload = {
        action: 'list',
      };
      const primaryDataMergedRes = await workflowDataSupplierApi(payload);
      this.layers = primaryDataMergedRes?.layer;
      if (primaryDataMergedRes.data.length > 0) {
        this.dataSupplierSubmit = primaryDataMergedRes.data.map((primaryDataLinkedItem) => {
          let status = getStatusNameById(primaryDataLinkedItem.status);
          let color = getColorTextStatusById(primaryDataLinkedItem.status);

          if (primaryDataLinkedItem.status === STATUS_FIELD.STATUS_SUBMIT) {
            status = getStatusNameById(STATUS_FIELD.STATUS_SUBMIT_NON_USER);
            color = getColorTextStatusById(STATUS_FIELD.STATUS_REPRENSENT_RETURN);
          }

          if (primaryDataLinkedItem.status === STATUS_FIELD.STATUS_CANCEL_SUBMMITED) {
            status = getStatusNameById(STATUS_FIELD.STATUS_CANCEL_SUBMMITED_NON_USER);
          }

          const approveInfo =
            primaryDataLinkedItem.status === STATUS_FIELD.STATUS_RETURN ||
            primaryDataLinkedItem.status === STATUS_FIELD.STATUS_REPRENSENT_RETURN ?
            primaryDataLinkedItem?.returner[0]?.name : primaryDataLinkedItem?.approver?.name;

          const dateApprove =
            primaryDataLinkedItem.status === STATUS_FIELD.STATUS_RETURN ||
            primaryDataLinkedItem.status === STATUS_FIELD.STATUS_REPRENSENT_RETURN ?
            primaryDataLinkedItem?.return_at[0]?.return_at : primaryDataLinkedItem?.approved_at[0]?.approved_at;
          
          const startDateFormat = formatDateTimeDataTable(primaryDataLinkedItem.start_date, true);
          const endDateFormat = formatDateTimeDataTable(primaryDataLinkedItem.end_date, true);

          return {
            ...primaryDataLinkedItem,
            ...primaryDataLinkedItem.branch,
            id: primaryDataLinkedItem.id,
            status: status,
            colorStatus: color,
            title: primaryDataLinkedItem.title || null,
            approver_info: approveInfo,
            date_submit: formatDateTimeDataTable(primaryDataLinkedItem.submitted_at[0]?.submitted_at),
            date_approval: formatDateTimeDataTable(dateApprove),
            emissions: formatNumberRealNum(primaryDataLinkedItem.emissions, { isAlowMore25digit: false }),
            organizational_division:
              ORGANIZATIONAL_DIVISION[primaryDataLinkedItem?.branch?.organizational_division - 1],
            status_id: primaryDataLinkedItem.status,
            product_name: primaryDataLinkedItem.name,
            product_code: primaryDataLinkedItem.code,
            product_period: primaryDataLinkedItem.start_date && primaryDataLinkedItem.end_date ? `${startDateFormat} - ${endDateFormat}` : startDateFormat || endDateFormat || null
          };
        });
      }
      this.defineTable();
    },
    formatNumber(num) {
      return addThoundSandComma(formatBigNumber(num, 35).replace(/[^0-9\.\-]+/g, ''), '', false, true);
    },
    initialView() {
      if (this.primaryDataMergedGrid) {
        this.dataSupplierSubmit = this.primaryDataMergedGrid.items.filter(
          (primaryDataLinkedItem) => primaryDataLinkedItem.id && primaryDataLinkedItem.id !== BLANK_ID,
        );
      }
      this.primaryDataMergedGrid = new CollectionView([...this.dataSupplierSubmit], {
        trackChanges: true,
      });
      this.flexGrid.columnGroups = this.getDataSupplierSubmit();
      this.primaryDataMergedGrid.currentItem = null;

      this.primaryDataMergedGrid.collectionChanged.addHandler(() => {
        if(this.primaryDataMergedGrid.itemsEdited.length > 0) {
          this.isEditDataTable = true
        }
      })

      setMinMaxSizeColumns(this.flexGrid, this.dataSupplierSubmit);
      this.markUnreadRows();
    },
    scrollToTop() {
      if (!this.flexGrid) {
        return;
      }

      let rc = this.flexGrid.cells.getCellBoundingRect(0, 0, true);
      this.flexGrid.scrollPosition = new wjcCore.Point(this.flexGrid.scrollPosition.x, -rc.top);
    },
    getDataSupplierSubmit() {
      const mapLayers = this.layers.map((layer) => ({
        header: layer.layer_name,
        binding: layer.layer_field,
        minWidth: 114,
        maxWidth: 980,
        allowSorting: false,
        multiLine: true,
        wordWrap: true,
        cssClassAll: 'primary-data-merged',
      }));
      return [
        {
          header: '#',
          binding: 'id',
          allowSorting: false,
          isReadOnly: true,
          visible: false,
        },
        {
          header: ' ',
          binding: 'unique_id',
          minWidth: getWidthOfDetailButton(),
          maxWidth: getWidthOfDetailButton(),
          allowSorting: false,
          isRequired: false,
          wordWrap: true,
          cssClass: 'btn-db',
          cellTemplate: CellMaker.makeButton({
            text: this.$t('primary_data_merge.button_detail'),
            click: (event, context) => this.onIdClicking(context),
          }),
          cssClassAll: 'height34 approval-cell_custom hide-filter',
        },
        {
          header: this.$t('primary_data_merge.table_status'),
          binding: 'status',
          minWidth: getWidthByTextContent(this.$t('primary_data_merge.table_status')),
          maxWidth: 350,
          allowSorting: false,
          isRequired: false,
          isReadOnly: true,
          multiLine: true,
          wordWrap: true,
          cellTemplate: (ctx) => `<span class="${ctx.item.colorStatus}">${ctx.item.status}</span>`,
          cssClassAll: 'height34 approval-cell_status',
        },
        {
          header: this.$t('primary_data_merge.table_name_form_data_merge'),
          binding: 'title',
          minWidth: 196,
          maxWidth: 980,
          allowSorting: false,
          multiLine: true,
          wordWrap: true,
          cssClassAll: 'primary-data-merged',
        },
        {
          header: this.$t('primary_data_merge.table_approver_info'),
          binding: 'approver_info',
          minWidth: getWidthByTextContent(this.$t('primary_data_merge.table_approver_info'), 20),
          maxWidth: 300,
          allowSorting: false,
          isRequired: false,
          multiLine: true,
          wordWrap: true,
          cssClassAll: 'primary-data-merged',
        },
        {
          header: this.$t('primary_data_merge.table_date_submit'),
          binding: 'date_submit',
          minWidth: 148,
          maxWidth: 200,
          allowSorting: false,
          multiLine: true,
          wordWrap: true,
          cssClassAll: 'primary-data-merged',
        },
        {
          header: this.$t('primary_data_merge.table_date_approved'),
          binding: 'date_approval',
          minWidth: 148,
          maxWidth: 200,
          allowSorting: false,
          multiLine: true,
          wordWrap: true,
          cssClassAll: 'primary-data-merged',
        },
        // TODO: mapLayers
        {
          header: this.$t('b_list_all.table_organizational_information'),
          align: 'center',
          collapseTo: 'organizational_division', 
          columns: [
            {
              header: this.$t('primary_data_merge.table_organizational_division'),
              binding: 'organizational_division',
              minWidth: getWidthByTextHeader(this.$t('primary_data_merge.table_organizational_division')),
              maxWidth: 980,
              allowSorting: false,
              isRequired: false,
              multiLine: true,
              wordWrap: true,
              cssClassAll: 'primary-data-merged',
            },
            {
              header: this.$t('primary_data_merge.table_company_name'),
              binding: 'company_name',
              minWidth: getWidthByTextHeader(this.$t('primary_data_merge.table_company_name')),
              maxWidth: 980,
              allowSorting: false,
              isRequired: false,
              multiLine: true,
              wordWrap: true,
              cssClassAll: 'primary-data-merged',
            },
            ...mapLayers,
          ],
        },
        {
          header: this.$t('b_list_product.table_products_name'),
          binding: 'product_name',
          minWidth: getWidthByTextHeader(this.$t('b_list_product.table_products_name')),
          maxWidth: 980,
          allowSorting: false,
          isRequired: false,
          multiLine: true,
          wordWrap: true,
          cssClassAll: 'primary-data-merged',
        },
        {
          header: this.$t('cfp_emissions_product.table_code_product'),
          binding: 'product_code',
          minWidth: getWidthByTextHeader(this.$t('cfp_emissions_product.table_code_product')),
          maxWidth: 980,
          allowSorting: false,
          isRequired: false,
          multiLine: true,
          wordWrap: true,
          cssClassAll: 'primary-data-merged',
        },
        {
          header: this.$t('cfp_emissions_product.table_lot_number'),
          binding: 'lot_number',
          minWidth: getWidthByTextHeader(this.$t('cfp_emissions_product.table_lot_number')),
          maxWidth: 980,
          allowSorting: false,
          isRequired: false,
          multiLine: true,
          wordWrap: true,
          cssClassAll: 'primary-data-merged',
        },
        {
          header: this.$t('supplier_products.label_form_product_period'),
          binding: 'product_period',
          minWidth: getWidthByTextHeader(this.$t('supplier_products.label_form_product_period')),
          maxWidth: 980,
          allowSorting: false,
          isRequired: false,
          multiLine: true,
          wordWrap: true,
          cssClassAll: 'primary-data-merged',
        },
        {
          header: this.$t('primary_data_merge.table_boundary_name'),
          binding: 'boundary_name',
          minWidth: getWidthByTextHeader(this.$t('primary_data_merge.table_boundary_name')),
          maxWidth: 980,
          allowSorting: false,
          isRequired: false,
          multiLine: true,
          wordWrap: true,
          cssClassAll: 'primary-data-merged',
        },
        {
          header: this.$t('primary_data_merge.table_process_name'),
          binding: 'process_name',
          minWidth: getWidthByTextHeader(this.$t('primary_data_merge.table_process_name')),
          maxWidth: 980,
          allowSorting: false,
          isRequired: false,
          multiLine: true,
          wordWrap: true,
          cssClassAll: 'primary-data-merged',
        },
        {
          header: this.$t('supplier_products.table_exhaust_original'),
          align: 'center',
          columns: [
            {
              header: this.$t('primary_data_merge.table_value_source_cfp'),
              binding: 'emissions',
              minWidth: getWidthByTextHeader(this.$t('primary_data_merge.table_value_source_cfp')),
              maxWidth: 980,
              allowSorting: false,
              isReadOnly: true,
              multiLine: true,
              wordWrap: true,
              cssClassAll: '',
              align: 'right',
            },
            {
              header: this.$t('primary_data_merge.table_unit_source_cfp'),
              binding: 'unit',
              minWidth: getWidthByTextHeader(this.$t('primary_data_merge.table_unit_source_cfp')),
              maxWidth: 980,
              allowSorting: false,
              isReadOnly: true,
              wordWrap: true,
              cssClassAll: '',
            },
          ],
        },
        {
          header: this.$t('primary_data_merge.table_note'),
          binding: 'note',
          minWidth: 196,
          maxWidth: 980,
          allowSorting: false,
          multiLine: true,
          wordWrap: true,
          cssClassAll: 'primary-data-merged',
        },
      ];
    },
    async onIdClicking(rowData) {
      if (!rowData.item?.id) return;
      await updateDataBeforeRedirect(rowData.item);
      await updateNotification({ id: rowData.item?.id });
      // TODO
      this.$router.push({ path: `/product-emission/process-data-link/${rowData.item.id}` });
    },
    defineTable() {
      this.approvalColumn = this.getDataSupplierSubmit();
      this.initialView();

      this.$nextTick(() => {
        this.scrollToTop();
      });
      this.isEmptySource = this.dataSupplierSubmit.length <= 0;
    },
    onChangeFullScreen(isFullScreen) {
      if (isFullScreen) {
        this.scrollToTop();
      } else {
        this.initialView();

        this.$nextTick(() => {
          this.scrollToTop();
        });
      }
    },
    onFlexGridInitialDone(flexgrid) {
      this.flexgrid = flexgrid;
      if (!flexgrid) {
        return;
      }
    },
    markUnreadRows() {
      if (!this.flexgrid) return;
      this.flexgrid.formatItem.addHandler((handler, eventHandler) => {
        let accessRowIndex = eventHandler.row;
        let currentItem = this.primaryDataMergedGrid.items[accessRowIndex];
        if (eventHandler.panel.cellType === wjGrid.CellType.RowHeader) {
          let currentItemUnread =
            currentItem !== undefined && currentItem['unread_flg'] !== undefined ? currentItem['unread_flg'] : 0;
          if (eventHandler.panel.cellType === wjGrid.CellType.RowHeader) {
            if (currentItemUnread) {
              eventHandler.cell.classList.add('wj-has-unread-mark');
            }
          }
        }
      });
    },
  },
  computed: {
    ...mapState('commonApp', ['isExpand']),
    ...mapState('registerData', ['isFullScreen']),

    descriptionLabelEmpty() {
      return this.$t('primary_data_merge.description_label_empty');
    },
    isHasData() {
      return this.isEditDataTable;
    },
  },
};
</script>
<style lang="scss">
.primary-data-supplier-submit {
  .wj-flexgrid {
    .wj-cells {
      .wj-state-active:not(.wj-state-multi-selected) {
        background: $monoOffWhite !important;
      }
      .wj-row {
        .disabled_row {
          background: $monoOffWhite !important;
          color: $monoMid !important;
          &.wj-state-multi-selected {
            background: $blueExtraLight !important;
          }
        }
      }
    }
  }
}
</style>
<style scoped lang="scss">
@import './styles/index.scss';
</style>
  